import React from "react"

import {SEO,FormElement} from '../components/Shared'
import {Layout,Routes,SocialIcons} from '../components/Layout';

import {H2White} from './index';
import styled from '@emotion/styled';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  background: black;
`;


const EnquiryPage = () => (
  <>
    <SEO title="Contact" desc={"7IDE is a group of designers, engineers, marketers and branding specalists, working together to help our clients grow. Get in touch with us to know more!"}/>
    <Routes/>
    <SocialIcons/>
    <Layout>
      <Container>
        <H2White>Enquiry</H2White><br/>
        <div>Send us a mail or contact us at <span itemProp="email">admin[@]7ide.co.in</span></div><br/>
        <FormElement/>
      </Container>
    </Layout>
  </>
)

export default EnquiryPage;
